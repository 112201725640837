  import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Portadaproducto from "../components/estrcutura prducto/portada";
import Descripcionproducto from "../components/estrcutura prducto/descripcion-general";

export default function Productoid() {
    const { id } = useParams();
    const [productoData, setproductoData] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
  
    useEffect(() => {
      obtenerDatospordcuto();
    }, []);
  
    const obtenerDatospordcuto = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/api/get/productos/${id}`
        );
        const curso = response.data;
        setproductoData(curso);
      } catch (error) {
        console.error(error);
      }
    };
  
    if (!productoData) {
      // Aquí podrías mostrar un spinner o un mensaje de carga mientras se obtienen los datos
      return <div>Cargando...</div>;
    }
    return (
      <div>
        <Portadaproducto  producto={productoData} />
        <Descripcionproducto  producto={productoData}  />
      </div>
    );
  }
  



