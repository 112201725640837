import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Grid } from "@mui/material";
import axios from "axios";
import "../../style/estrcutura producto/productestruc.css";
import "../../style/estructura racks/estrucrack.css";

import AOS from "aos";
import "aos/dist/aos.css";

/* imagenes */

import check from "../../img/racks/estructura/check.png";

export default function Descripcionproducto({ producto }) {


  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 1000,
    });
  }, []);

  return (
    <>
      <section className="section-descripcion-general">
        <div
          className="titulo-descripcion-general-prducto"
          data-aos="fade-down"
        >
          <img
            className="imagen-titulo-generalprducto"
            src={check}
            alt="stacker"
            title="stacker"
          />
          <h1 className="titulo-descripcion-general">
            CARACTERISTICAS ESPECIALES
          </h1>
        </div>
      </section>
      <section className="section-caracterizacion-prducto" data-aos="fade-down">
        <div className="texto-caracterizacion">
          {producto.caracteristicas_especiales}
        </div>
        <div className="video-caracterizacion-producto" data-aos="fade-down">
          <ReactPlayer
            url={producto.video}
            className="videoproducto"
            width="90%"
            height="100%"
            style={{ maxHeight: "70vh" }}
            loop
            controls
          />
        </div>
      </section>
    </>
  );
}
