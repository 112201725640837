import React, { useState } from 'react';
import axios from 'axios';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  InputAdornment,
  TextField,
  TextareaAutosize,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,

} from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import fondo from '../../img/contacto/logo.png';

import '../../style/contacto/contacto.css';



export default function Formularioservicio() {
  const [loading, setLoading] = useState(false); 
  const [responseStatus, setResponseStatus] = useState(null);
  const [numArchivos, setNumArchivos] = useState(1);
  const [formData, setFormData] = useState({
    nombre: "",
    empresa: "",
    region:"",
    ciudad:"",
    email: "",
    telefono: "",
    texto: "",
    pdf1: null,
    pdf2: null,
    pdf3: null,
  });


  const handleClose = () => {
    // Cierra el modal o popup
    setResponseStatus(null);
  };

  const handleAgregarArchivo = () => {
    if (numArchivos < 3) {
      setNumArchivos(numArchivos + 1);
    }
  };

  const handleFileChange = (event, index) => {
    const { name, files } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: files.length > 0 ? files[0] : null,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const apiUrl = 'https://mail.aleramatech.com/mailStackerTecnico.php';

    const formDataToSend = new FormData();
    formDataToSend.append('nombre', formData.nombre);
    formDataToSend.append('empresa', formData.empresa);
    formDataToSend.append('region', formData.region);
    formDataToSend.append('ciudad', formData.ciudad);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('telefono', formData.telefono);
    formDataToSend.append('texto', formData.texto);
    formDataToSend.append('pdf1', formData.pdf1);
    formDataToSend.append('pdf2', formData.pdf2);
    formDataToSend.append('pdf3', formData.pdf3);

    try {
      setLoading(true);

     
     
      const response = await axios.post(apiUrl, formDataToSend);
      setResponseStatus(response.data);

      setFormData({
          nombre: "",
          empresa: "",
          region:"",
          ciudad:"",
          email: "",
          telefono: "",
          texto: "",
          pdf1: null,
          pdf2: null,
          pdf3: null,
      })
      
    } catch (error) {
      console.error("Error submitting form:", error);
      console.error("Error ", Response);
    } finally {
      setLoading(false);

      // Cierra el diálogo después de 100 segundos
      setTimeout(() => {
        handleClose();
      }, 100);  // 100 segundos en milisegundos
    }
  };


  return (
    <>
      <div className="contiene-formulario-servicio">
        <section className="titulo-formulario-requerimiento">
          <div className="titulo-informacion-requerimiento">
            <h1 className="titulo-requerimiento">Ingresa tu consulta </h1>
            <span className="descripcion-formulario"> Informa tu consulta para recibir asesoría especializada.</span>
          </div>
          <div className="contendor-logo-formulario">
            <img src={fondo} alt="" title="" className="logo-formulario" layout="responsive" />
          </div>
        </section>
        <section className='formulario-requerimiento'>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} className='contenedor'>
                <TextField
                  className="input-formulario"
                  label="Nombre"
                  variant="outlined"
                  fullWidth
                  name='nombre'
                  value={formData.nombre}
                  onChange={(e) => setFormData({ ...formData, nombre: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6} className='contenedor'>
                <TextField
                  className="input-formulario"
                  label="Empresa"
                  variant="outlined"
                  fullWidth
                  name='empresa'
                  value={formData.empresa}
                  onChange={(e) => setFormData({ ...formData, empresa: e.target.value })}
                 
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} className='contenedor'>
                <TextField
                  className="input-formulario"
                  label="Region"
                  variant="outlined"
                  fullWidth
                  name='region'
                  value={formData.region}
                  onChange={(e) => setFormData({ ...formData, region: e.target.value })}
                 
                />
              </Grid>
              <Grid item xs={12} sm={6} className='contenedor'>
                <TextField
                  className="input-formulario"
                  label="Ciudad"
                  variant="outlined"
                  fullWidth
                  name='ciudad'
                  value={formData.ciudad}
                  onChange={(e) => setFormData({ ...formData, ciudad: e.target.value })}
                 
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} className='contenedor'>
                <TextField
                  className="input-formulario"
                  label="Correo Electrónico"
                  variant="outlined"
                  type="email"
                  name='email'
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  fullWidth
                 
                />
              </Grid>
              <Grid item xs={12} sm={4} className='contenedor'>
                <TextField
                  className="input-formulario"
                  label="Teléfono"
                  variant="outlined"
                  type="tel"
                  name='telefono'
                  value={formData.telefono}
                  onChange={(e) => setFormData({ ...formData, telefono: e.target.value })}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+569</InputAdornment>,
                  }}
                  fullWidth
                 
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className='contenedor'>
                <TextField
                  className="input-formulario"
                  label="Mensaje"
                  variant="outlined"
                  multiline
                  name='texto'
                  value={formData.texto}
                  onChange={(e) => setFormData({ ...formData, texto: e.target.value })}
                  rows={4}
                  fullWidth
                 
                />
              </Grid>
              <Grid item xs={12} className='contenedor'>
              <div className="contenedor-imagenes-despues-mensaje">
                {[...Array(numArchivos)].map((_, index) => (
                  <input
                    key={index}
                    className='seleccion-imagen'
                    type="file"
                    accept="application/pdf, image/*"
                    name={`pdf${index + 1}`}
                    onChange={(event) => handleFileChange(event, index + 1)}
                  />
                ))}
              </div>
              {numArchivos < 3 && (
                <Button type="button" onClick={handleAgregarArchivo}>
                  Agregar Archivo
                </Button>
              )}
            </Grid>

            <Grid item xs={12} className='button-contenedor'>
              <Button type="submit" className='buton-enviar' disabled={loading}>
                {loading ? 'Enviando...' : 'Enviar'}
              </Button>
            </Grid>
            </Grid>
          </form>
        </section>
      </div>
      <Dialog open={loading || responseStatus !== null} onClose={handleClose}>
        <DialogContent>
          {/* Agrega el componente CircularProgress aquí */}
          {loading && <CircularProgress />}
        </DialogContent>
      </Dialog>
    </>
  );
}