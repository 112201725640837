import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import "../../style/otros/louding.css";

/* imagenes */
import logo from "../../img/navbar/logo.png"

const LoaderModal = () => {
  return (
    <div className="overlay">
      <div className="modal">
        <div>
          <img src={logo} alt="stacker" className="logo-louding"/>
          <div className="loading-spinner">
  <div className="loading-spinner-inner">
    <div className="loading-spinner-circle"></div>
    <div className="loading-spinner-circle"></div>
    <div className="loading-spinner-circle"></div>
    <div className="loading-spinner-circle"></div>
    <div className="loading-spinner-circle"></div>
  </div>
</div>
</div>
      </div>
    </div>
  );
};

export default LoaderModal;