import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../style/nosotros/nosotros.css";
import "../../style/producto/producto.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Portadaproducto() {
  const [windowWidth, setWindowWidth] = useState(0);
  const [portadasData, setPortadasData] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 1000,
    });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Agregar el event listener para detectar cambios en el tamaño de la ventana
    window.addEventListener("resize", handleResize);

    // Obtener el ancho de la ventana al cargar el componente
    setWindowWidth(window.innerWidth);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/api/get/all/portadaproductos`
        );
        setPortadasData(response.data)

      } catch (error) {
        console.error("Error al obtener los datos de portadas:", error); 
      }
    }
    fetchData();
  }, []);

  return (
    <section className="portada-home">
      {portadasData.map((portadaItem) => (
        <div key={portadaItem.id} className="contenedor-img-nosotros">
          {portadaItem.imagen && portadaItem.imagen_responsive && (
            <img
              src={
                windowWidth < 1000
                  ? `${backendURL}/${portadaItem.imagen_responsive}`
                  : `${backendURL}/${portadaItem.imagen}`
              }
              alt={portadaItem.alt_seo}
              title={portadaItem.alt_seo}
              className="portadasitem"
            />
          )}

          <div className="info-portada-producto" data-aos="fade-down">
            <h1 className="titulo-portada-producto"> {portadaItem.nombre} </h1>
          </div>
        </div>
      ))}
    </section>
  );
}
