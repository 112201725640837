import React from "react";



import Portadaproducto from "../components/producto/portada"
import Filtrar from "../components/producto/filtrado"

export default function Productos() {
    
    return(
        <>
        <section><Portadaproducto/></section>
        <section><Filtrar/></section>
        
         
        </>
    )

}
