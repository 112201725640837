import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../style/home/home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Grid } from "@mui/material";
import LoaderModal from "../otroscomponentes/louding";
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

/* imagenes */
import portada from "../../img/home/portada/portada.png";
import portadaresponsiva from "../../img/home/portada/portadaresponsiva.png";

export default function Portadahome() {
  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 8000,
    });
  }, []);

  const [windowWidth, setWindowWidth] = useState(0);
  const [portadasData, setPortadasData] = useState([]);
  const [portadasGaleriaData, setPortadasGaleriaData] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/get/all/portadas`);
        setPortadasData(response.data);
        if (response.status === 200) {
          setTimeout(() => {
            setIsLoading(false);
            setShowLoader(false); // Ocultar el modal de carga después de 5 segundos
          }, 2000);
          // Obtener datos de portadasgaleria para el primer elemento (puedes ajustar según tus necesidades)
          if (response.data.length > 0) {
            const portadaGaleriaResponse = await axios.get(
              `${backendURL}/api/get/all/portadasgaleria?id=${response.data[0].id}`
            );
            setPortadasGaleriaData({
              [response.data[0].id]: portadaGaleriaResponse.data,
            });
          }
        } else {
          // Manejar el error, por ejemplo, mostrando un mensaje al usuario
          console.error("Error al obtener los datos de portadas:", response);
        }
      } catch (error) {
        console.error("Error al obtener los datos de portadas:", error);
      }
    };

    fetchData();
  }, []);

  // Función para cargar los datos de portadasgaleria según el ID
  const loadPortadasGaleriaData = async (portadaId) => {
    try {
      const portadaGaleriaResponse = await axios.get(
        `${backendURL}/api/get/all/portadasgaleria?id=${portadaId}`
      );
      setPortadasGaleriaData((prevData) => ({
        ...prevData,
        [portadaId]: portadaGaleriaResponse.data,
      }));
    } catch (error) {
      console.error("Error al obtener los datos de portadasgaleria:", error);
    }
  };

  return (
    <>
      {showLoader && <LoaderModal />}{" "}
      {/* Mostrar el modal de carga solo cuando showLoader es verdadero */}
      {!showLoader && (
        <section className="portada-home">
          {portadasData.map((portadaItem) => (
            <div key={portadaItem.id} className="contenedor-img">
              <img
                src={windowWidth < 840 ? portadaresponsiva : portada}
                className="portada-nosotros"
                alt="stacker"
                title="stacker"
              />
              <div className="contenedor-portada-produtohome">
                <Grid container>
                  <Grid
                   item
                    xs={6}
                    className="contiene-infoportadahome"
                    data-aos="fade-down"
                  >
                    <div className="info-portada-home">
                      <h1 className="titulo-portada-home">
                        {portadaItem.titulo}
                      </h1>
                      <h3 className="subtitulo-portada-home">
                        {portadaItem.descripcion}
                      </h3>
                      <div className="boton-portada-home">
                        <a href={portadaItem.url}>
                          <button className="buttons type2">
                            <span className="btn-txts">VER MÁS</span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} data-aos="fade-down">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={1}
                      modules={[Autoplay, EffectFade]}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                      }}
                      loop={true}
                      className="mySwiper"
                    >
                      {portadasGaleriaData[portadaItem.id] &&
                        portadasGaleriaData[portadaItem.id].map(
                          (galeriaItem) => (
                            <SwiperSlide key={galeriaItem.id}>
                              <div className="contenedor-maquina-portada-home">
                                <img
                                  src={`${backendURL}/${galeriaItem.imagen_web}`}
                                  alt={galeriaItem.titulo}
                                  title={galeriaItem.titulo}
                                  className="maquinariaportada"
                                />
                              </div>
                            </SwiperSlide>
                          )
                        )}
                    </Swiper>
                  </Grid>
                </Grid>
              </div>
            </div>
          ))}
        </section>
      )}
    </>
  );
}
