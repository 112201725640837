import React, { createContext, useContext, useState, useEffect } from "react";

const CarritoContext = createContext();

export const CarritoProvider = ({ children }) => {
  const [productosEnCarrito, setProductosEnCarrito] = useState([]);
  const [cantidadProductos, setCantidadProductos] = useState(0);

  const toggleCartVisibility = () => {
    setProductosEnCarrito((prevProductos) => !prevProductos);
  };

  const agregarProductoAlCarrito = (producto) => {
    setProductosEnCarrito((prevProductos) => {
      const prevProductosArray = Array.isArray(prevProductos)
        ? prevProductos
        : [];

      const index = prevProductosArray.findIndex((p) => p.id === producto.id);

      if (index !== -1) {
        prevProductosArray[index].cantidad += 1;
      } else {
        prevProductosArray.push({ ...producto, cantidad: 1 });
      }

      setCantidadProductos(prevProductosArray.length);

      localStorage.setItem("/cart", JSON.stringify(prevProductosArray));

      return prevProductosArray;
    });
  };

  const eliminarProductoDelCarrito = (productoId) => {
    setProductosEnCarrito((prevProductos) => {
      const nuevosProductos = prevProductos.filter(
        (producto) => producto.id !== productoId
      );

      setCantidadProductos(nuevosProductos.length);
      localStorage.setItem("/cart", JSON.stringify(nuevosProductos));

      return nuevosProductos;
    });
  };

  useEffect(() => {
    const productosGuardados = localStorage.getItem("/cart");
    if (productosGuardados) {
      const productos = JSON.parse(productosGuardados);
      setProductosEnCarrito(productos);
      setCantidadProductos(productos.length);
    }
  }, []);

  return (
    <CarritoContext.Provider
      value={{
        cantidadProductos,
        toggleCartVisibility,
        agregarProductoAlCarrito,
        productosEnCarrito,
        eliminarProductoDelCarrito,
      }}
    >
      {children}
    </CarritoContext.Provider>
  );
};

export const useCarrito = () => {
  const context = useContext(CarritoContext);
  return context;
};
