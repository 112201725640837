import React from "react";
import ContactoPrincipal from "../components/contacto/contacto-principal";


export default function Contacto (){
    return(
        <>
        <ContactoPrincipal/>
        </>
    )
}