
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid } from "@mui/material";
import axios from 'axios';
import { FaTimes, FaArrowLeft, FaArrowRight } from "react-icons/fa";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
 import { useCarrito } from "../context/carrito-cotizar";
import { useCarritoContexto } from "../context/cotizar-final"; 



export default function Cotizarrack({rack}) {
  const { agregarProductoAlCarrito } = useCarrito(); 
  const { agregarcotizar } = useCarritoContexto();
  const [galeriaData, setGaleriaData] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

 
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/get/all/galeriaracks`);
        
        // Convertir rack.id a número si es necesario
        const filteredData = response.data.filter(item => item.id_racks === String(rack.id));
        
        setGaleriaData(filteredData);
      } catch (error) {
        console.error('Error al obtener datos de la galería de racks', error);
      }
    };

    fetchData();
  }, [rack]);


  if (!rack) {
    return null;
  }

  const cotizarProductos = () => {
    const productofinal = {
      id:rack.id,
      tipo: "rack",
      cantidad:"1",
      imagen:rack.imagen_portada,
      precio:"consultar por mensaje",
     nombre:rack.nombre 
    };
    agregarcotizar(productofinal)
  };

  const handleAgregarAlCarrito = () => {
    const productofinal = {
      id:rack.id,
      tipo: "rack",
      cantidad:"1",
      imagen:rack.imagen_portada,
      precio:"consultar por mensaje",
     nombre:rack.nombre 
    };
    agregarProductoAlCarrito(productofinal); 
  };
  return (
    <>
      <section className="estructura-rack-cotizar">
        <div className="cotizar-rack">
          <Grid container>
            <Grid item xs={12} sm={6}>
            <a href="/Contacto">
              <button   onClick={cotizarProductos} 
              className="button-cotizar-rack "
          
          >
            <p>COTIZAR</p>
          </button> 
          </a>
          </Grid>
          </Grid>
        
          <Grid item xs={12} sm={6}>  <a href="/cart">
                <button className="button-cotizar-rack"  onClick={handleAgregarAlCarrito}>
                  AGREGAR
                </button>
              </a></Grid>
        
        </div>
      </section>
      <section className="imagen-cotizar-rack">
        <Swiper
          slidesPerView={4}
          spaceBetween={1}
          breakpoints={{
            240: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            800: { slidesPerView: 3, spaceBetween: 1 },
            1565: {
              slidesPerView: 3,
              spaceBetween: 2,
            },
            1566: {
              slidesPerView: 4,
              spaceBetween: 2,
            },
          }}
          pagination={{
            clickable: true,
          }}
          loop={true}
          
          modules={[Pagination, Navigation]}
          className="video-home-servicios"
        >
         {galeriaData.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="contenedor-imagenes">
              {item.img && (
                <img
                src={`${backendURL}/${item.img}`}
                  alt="stacker"
                  title="stacker"
                  className="todogaleria"
                />)}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  );
}
