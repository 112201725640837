import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import "../../style/racks/racks.css";
import "../../style/producto/producto.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import LoaderModal from "../otroscomponentes/louding";
import axios from "axios";
import { GrVirtualMachine } from "react-icons/gr";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";

export default function Filtrar() {
  const [mostrarTipoBateria, setMostrarTipoBateria] = useState(false);
  const [mostrarCapacidad, setMostrarCapacidad] = useState(false);
  const [filtroCapacidadBateria, setFiltroCapacidadBateria] = useState("");
  const [filtroCapacidad, setFiltroCapacidad] = useState("");
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [filtroCategoriaSeleccionada, setFiltroCategoriaSeleccionada] =
    useState(null);
  const [alturaSeleccionada, setAlturaSeleccionada] = useState("");
  const [alturaRelacionadas, setalturaRelacionadas] = useState([]);
  const [filtroAltura, setfiltroAltura] = useState([]);
  const [capacidadesRelacionadas, setCapacidadesRelacionadas] = useState([]);
  const [capacidadFiltradas, setCapacidadfiltrado] = useState([]);
  const [capacidadBateriaSeleccionada, setCapacidadBateriaSeleccionada] = useState("");
  const [capacidadBateriaTipos, setCapacidadBateriaTipos] = useState([]);
  const [filtroBateria, setFiltroBateria] = useState("");
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
  const [productos, setProductos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tiposBateria, setTiposBateria] = useState([]);
  const [tipoBateriaSeleccionado, setTipoBateriaSeleccionado] = useState("");
  const [seleccionDesdeBoton, setSeleccionDesdeBoton] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedProductId = queryParams.get("id");

  const [habilitarTipoBateria, setHabilitarTipoBateria] = useState(false);
  const [habilitarFiltroCapacidad, setHabilitarFiltroCapacidad] =
    useState(false);
  const [habilitarAltura, setHabilitarAltura] = useState(false);
  const [habilitarCapacidadBateria, setHabilitarCapacidadBateria] =
    useState(false);
  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 1000,
    });
  }, []);

  const limpiarFiltros = () => {
    setCategoriaSeleccionada(null);
    setTipoBateriaSeleccionado("");
    setCapacidadBateriaSeleccionada("");
    setCapacidadfiltrado("");
    setFiltroCategoriaSeleccionada(null);
    setFiltroCapacidadBateria("");
    setFiltroCapacidad("");
    setfiltroAltura([]);
    setCapacidadesRelacionadas([]);
    setCapacidadfiltrado([]);
    setCapacidadBateriaTipos([]);
    setProductosFiltrados([]);
    setCategorias([]);
    setAlturaSeleccionada([]);
    setHabilitarFiltroCapacidad("");
    setHabilitarAltura("");
    setHabilitarCapacidadBateria("");
    setHabilitarTipoBateria("");

    // Añade la siguiente línea para refrescar los productos filtrados
    setProductosFiltrados(filtrarPorCategoria(productos, null));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseProductos = await axios.get(
          `${backendURL}/api/get/all/productos`
        );
        const responseCategorias = await axios.get(
          `${backendURL}/api/get/all/categorias`
        );

        if (
          responseProductos.status === 200 &&
          responseCategorias.status === 200
        ) {
          const productosData = responseProductos.data.reverse();
          setProductos(productosData);

          const categoriasData = responseCategorias.data;
          setCategorias(categoriasData);

          let productosFiltradosPorCategoria;

          if (categoriaSeleccionada) {
            // Si hay una categoría seleccionada, úsala
            productosFiltradosPorCategoria = filtrarPorCategoria(
              productosData,
              categoriaSeleccionada,
              selectedProductId
            );
          } else if (selectedProductId && !seleccionDesdeBoton) {
            // Si hay un ID seleccionado y no es una selección desde botón, úsalo como categoría
            productosFiltradosPorCategoria = filtrarPorCategoria(
              productosData,
              selectedProductId,
              categoriaSeleccionada
            );
          } else {
            // Si no hay ID seleccionado o es una selección desde botón, usa la categoría normal
            productosFiltradosPorCategoria = filtrarPorCategoria(
              productosData,
              categoriaSeleccionada,
              selectedProductId
            );
            // Restablecer el ID de producto a null
            setSeleccionDesdeBoton(false);
          }

          productosFiltradosPorCategoria = filtrarPorBateria(
            productosFiltradosPorCategoria,
            filtroBateria
          );

          setProductosFiltrados(productosFiltradosPorCategoria.reverse());
          setIsLoading(false);

          const tiposBateriaUnicos = [
            ...new Set(
              productosFiltradosPorCategoria.map((producto) => producto.bateria)
            ),
          ];
          setTiposBateria(tiposBateriaUnicos);

          const capacidadBateriaTiposUnicos = [
            ...new Set(
              productosFiltradosPorCategoria.map(
                (producto) => producto.capacidad
              )
            ),
          ];
          setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);

          const capacidadfiltrado = [
            ...new Set(
              productosFiltradosPorCategoria.map(
                (producto) => producto.capacidad_bateria
              )
            ),
          ];
          setCapacidadfiltrado(capacidadfiltrado);

          const alturafiltrado = [
            ...new Set(
              productosFiltradosPorCategoria.map((producto) => producto.altura)
            ),
          ];
          setfiltroAltura(alturafiltrado);

          // Si alturaSeleccionada está vacío, establecerlo como "Seleccionar"
          if (!alturaSeleccionada) {
            setAlturaSeleccionada("");
          }
        } else {
          console.error("Error al obtener datos de productos o categorías");
        }
      } catch (error) {
        console.error("Error de red", error);
      }
    };

    fetchData();
  }, [
    categoriaSeleccionada,
    filtroBateria,
    selectedProductId,
    seleccionDesdeBoton,
  ]);
  const filtrarPorCategoria = (productos, categoriaId) => {
    if (categoriaId === null) {
      return productos;
    }
    const productosFiltrados = productos.filter(
      (producto) => producto.id_categoria.toString() === categoriaId.toString()
    );
    return productosFiltrados;
  };

  const filtrarPorBateria = (productos, bateria) => {
    if (!bateria) {
      return productos;
    }

    const productosFiltrados = productos.filter(
      (producto) => producto.bateria.toLowerCase() === bateria.toLowerCase()
    );

    // Actualizar los tipos de capacidad_bateria al filtrar por batería
    const capacidadBateriaTiposUnicos = [
      ...new Set(productosFiltrados.map((producto) => producto.capacidad)),
    ];
    setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);

    return productosFiltrados;
  };

  const filtrarPorCapacidad = (productos, capacidad) => {
    if (!capacidad) {
      return productos; // Si no hay capacidad seleccionada, devuelve los productos sin cambios
    }

    const productosFiltrados = productos.filter(
      (producto) =>
        String(producto.capacidad).toLowerCase() ===
        String(capacidad).toLowerCase()
    );
    const alturaUnicos = [
      ...new Set(productosFiltrados.map((producto) => producto.altura)),
    ];
    setalturaRelacionadas(alturaUnicos); // Cambia aquí

    return productosFiltrados;
  };

  const filtrarAltura = (productos, altura) => {
    if (!altura) {
      return productos; // Si no hay altura seleccionada, devuelve los productos sin cambios
    }

    const productosFiltrados = productos.filter(
      (producto) => producto.altura.toLowerCase() === altura.toLowerCase()
    );

    // Obtener y almacenar las alturas relacionadas a los productos filtrados
    const alturasRelacionadas = [
      ...new Set(
        productosFiltrados.map((producto) => producto.capacidad_bateria)
      ),
    ];
    setCapacidadfiltrado(alturasRelacionadas);

    return productosFiltrados;
  };

  const filtrarPorCapacidadBateria = (productos, capacidadBateria) => {
    if (!capacidadBateria) {
      return productos; // Si no hay capacidad seleccionada, devuelve los productos sin cambios
    }

    const productosFiltrados = productos.filter(
      (producto) =>
        producto.capacidad_bateria.toLowerCase() ===
        capacidadBateria.toLowerCase()
    );

    return productosFiltrados;
  };

  const handleTipoBateriaChange = (tipoBateria) => {
    setTipoBateriaSeleccionado(tipoBateria);
    setHabilitarFiltroCapacidad(true);

    const productosFiltradosPorTipoBateria = filtrarPorBateria(
      productos,
      tipoBateria
    );
    const productosFiltradosPorCategoria = filtrarPorCategoria(
      productosFiltradosPorTipoBateria,
      categoriaSeleccionada
    );

    // Actualiza los tipos de capacidad_bateria después de filtrar por batería y categoría
    const capacidadBateriaTiposUnicos = [
      ...new Set(
        productosFiltradosPorCategoria.map((producto) => producto.capacidad)
      ),
    ];
    setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);

    setProductosFiltrados(productosFiltradosPorCategoria.reverse());
  };

  const handleCapacidadBateriaChange = (capacidad) => {
    setFiltroCapacidadBateria(capacidad);
    setHabilitarAltura(true);
    // Filtrar productos por capacidad seleccionada
    const productosFiltradosPorCapacidad = filtrarPorCapacidad(
      productos, // Utiliza la capacidad actualizada
      capacidad
    );

    // Filtrar por categoría
    const productosFiltradosPorCategoria = filtrarPorCategoria(
      productosFiltradosPorCapacidad,
      categoriaSeleccionada
    );

    // Filtrar por tipo de batería
    const productosFiltradosPorTipoBateria = filtrarPorBateria(
      productosFiltradosPorCategoria,
      tipoBateriaSeleccionado
    );

    // Obtener y almacenar las capacidades relacionadas a los productos filtrados
    const capacidadesRelacionadas = [
      ...new Set(
        productosFiltradosPorTipoBateria.map((producto) => producto.altura)
      ),
    ];
    setalturaRelacionadas(capacidadesRelacionadas);

    setProductosFiltrados(productosFiltradosPorTipoBateria.reverse());
  };

  const handleAlturaChange = (alturas) => {
    setAlturaSeleccionada(alturas);
    setHabilitarCapacidadBateria(true);

    setCapacidadfiltrado(alturas);
    // Filtrar productos por capacidad seleccionada
    const productosFiltradosPoraltura = filtrarAltura(productos, alturas);
    // Filtrar por categoría
    const productosFiltradosPorCategoria = filtrarPorCategoria(
      productosFiltradosPoraltura,
      categoriaSeleccionada
    );

    // Filtrar por tipo de batería
    const productosFiltradosPorTipoBateria = filtrarPorBateria(
      productosFiltradosPorCategoria,
      tipoBateriaSeleccionado
    );
    // Filtrar por capacidad
    const productosFiltradosPorcapacidad = filtrarPorCapacidad(
      productosFiltradosPorTipoBateria,
      filtroCapacidadBateria
    );

    const capacidadesRelacionadas = [
      ...new Set(
        productosFiltradosPorTipoBateria.map((producto) => producto.altura)
      ),
    ];
    setfiltroAltura(capacidadesRelacionadas);

    // Obtener y almacenar las alturas relacionadas a los productos filtrados
    const alturasRelacionadas = [
      ...new Set(
        productosFiltradosPorcapacidad.map(
          (producto) => producto.capacidad_bateria
        )
      ),
    ];
    setCapacidadfiltrado(alturasRelacionadas);

    // Actualizar los productos filtrados después de filtrar por alturas
    setProductosFiltrados(productosFiltradosPorcapacidad.reverse());
  };

  const handleCapacidadChange = (capacidadbateria) => {
    setCapacidadBateriaSeleccionada(capacidadbateria);
    // Filtrar productos por capacidad seleccionada
    const productosFiltradosPorCapacidad = filtrarPorCapacidadBateria(
      productos, // Utiliza la capacidad actualizada
      capacidadbateria
    );
    // Filtrar por categoría
    const productosFiltradosPorCategoria = filtrarPorCategoria(
      productosFiltradosPorCapacidad,
      categoriaSeleccionada
    );

    // Filtrar por tipo de batería
    const productosFiltradosPorTipoBateria = filtrarPorBateria(
      productosFiltradosPorCategoria,
      tipoBateriaSeleccionado
    );
    // Filtrar por capacidad
    const productosFiltradosPorcapacidad = filtrarPorCapacidad(
      productosFiltradosPorTipoBateria,
      filtroCapacidadBateria
    );
    //filtrar por altura
    const productosFiltradosPoraltura = filtrarAltura(
      productosFiltradosPorcapacidad,
      alturaSeleccionada
    );

    // Actualizar los tipos de capacidad_bateria después de filtrar por capacidad y categoría
    const capacidadBateriaTiposUnicos = [
      ...new Set(
        productosFiltradosPoraltura.map(
          (producto) => producto.capacidad_bateria
        )
      ),
    ];
    setCapacidadfiltrado(capacidadBateriaTiposUnicos);

    setProductosFiltrados(productosFiltradosPoraltura.reverse());
  };

  const toggleFiltros = () => {
    setMostrarFiltros(!mostrarFiltros);
    if (!mostrarFiltros) {
      // Si se hace clic en "titulo-catalogo-producto", no se reinician los filtros
      setCurrentPage(1); // Restablecer la página a la primera
    }
  };

  const numElementosPorFila = mostrarFiltros ? 4 : 3;

  const filtroGridProps = mostrarFiltros ? "show-filters" : "hide-filters";
  const productosGridProps = mostrarFiltros
    ? { xs: 12, sm: 6, md: 9 }
    : { xs: 12, sm: 6, md: 12 };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const [buttonsToShow, setButtonsToShow] = useState(3);

  const productosActuales = productos.slice(startIndex, endIndex);

  useEffect(() => {
    updateButtonsToShow();
  }, [currentPage, productos.length]);

  const updateButtonsToShow = () => {
    const totalPages = Math.ceil(productos.length / itemsPerPage);
    let newButtonsToShow = 3;

    if (totalPages <= 3) {
      newButtonsToShow = totalPages;
    } else if (currentPage <= 2) {
      newButtonsToShow = 3;
    } else if (currentPage + 1 >= totalPages) {
      newButtonsToShow = totalPages;
    } else {
      newButtonsToShow = currentPage + 1;
    }

    setButtonsToShow(newButtonsToShow);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const totalPagesFiltered = Math.ceil(
    productosFiltrados.length / itemsPerPage
  );
  return (
    <>
      {isLoading ? (
        <LoaderModal />
      ) : (
        <>
          <section className="categoria-prducto">
            <Grid
              container
              id="contiene-categoria-filtrado-uno"
              className="contiene-categoria-filtrado-uno"
            >
              <Swiper
                slidesPerView={4}
                spaceBetween={1}
                breakpoints={{
                  240: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  500: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  600: {
                    slidesPerView: 2,
                    spaceBetween: 1,
                  },
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 1,
                  },
                  1000: { slidesPerView: 5, spaceBetween: 1 },
                  1100: {
                    slidesPerView: 6,
                    spaceBetween: 2,
                  },
                  1566: {
                    slidesPerView: 7,
                    spaceBetween: 2,
                  },
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="video-home-servicios"
              >
                {categorias.map((categoria) => (
                  <SwiperSlide
                    key={categoria.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ padding: "0px" }} data-aos="fade-down">
                      <button
                        onClick={() => {
                          setHabilitarTipoBateria(true);
                          setMostrarTipoBateria(true);
                          setCategoriaSeleccionada(categoria.id);
                          setCurrentPage(1); // Reiniciar el paginador al hacer clic en una categoría
                        }}
                        className="buttoncategoria"
                      >
                        <div className="icono-categoria">
                          {categoria.imagen && (
                            <img
                              className="maquina-cateogria"
                              style={{ width: "80px" }}
                              src={`${backendURL}/${categoria.imagen}`}
                            />
                          )}
                        </div>
                        <strong>
                          {" "}
                          <h1> {categoria.descripcion}</h1>
                        </strong>
                       
                      </button>
                    </div>{" "}
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </section>
          <section className="principal filtrado" data-aos="fade-down">
            <div className="contenedor filtrar">
              <div className="contenedor-filtrado">
                <label
                  className="burger"
                  htmlFor="burger" /*  onClick={limpiarFiltros} */
                >
                  <input type="checkbox" id="burger" onClick={toggleFiltros} />
                  <p>FILTRAR</p>
                  <span></span>
                  <span></span>
                  <span></span>
                </label>
              </div>
            </div>
          </section>

          <section className="section-productos">
            <Grid container spacing={2} className="productosfiltrados">
              <Grid item className={`transition-container ${filtroGridProps}`}>
                {mostrarFiltros && (
                  <ul className="opciones-filtrado">
                    <li>
                      <label>
                        <p className="titulo-select-producto">CATEGORÍA</p>
                        <select
                          className="select-producto"
                          value={categoriaSeleccionada}
                          onChange={(e) => {
                            const selectedCategory = categorias.find(
                              (cat) => cat.id === e.target.value
                            );
                            setCategoriaSeleccionada(e.target.value);
                            setFiltroCategoriaSeleccionada(
                              selectedCategory ? selectedCategory.nombre : ""
                            );
                            setHabilitarTipoBateria(true);
                          }}
                        >
                          <option value="">Seleccionar </option>
                          {categorias.map((categoria) => (
                            <option key={categoria.id} value={categoria.id}>
                              {categoria.descripcion}
                            </option>
                          ))}
                        </select>
                      </label>
                    </li>

                    <li></li>

                    <li>
                      <label>
                        <p className="titulo-select-producto">Energía</p>
                        <select
                          className="select-producto"
                          value={tipoBateriaSeleccionado}
                          onChange={(e) => {
                            handleTipoBateriaChange(e.target.value);
                            setMostrarCapacidad(true);
                          }}
                          disabled={!habilitarTipoBateria}
                        >
                          <option value="">Seleccionar</option>
                          {tiposBateria.map((tipo) => (
                            <option key={tipo} value={tipo}>
                              {tipo}
                            </option>
                          ))}
                        </select>
                      </label>
                    </li>

                    <li>
                      <div>
                        <label>
                          <p className="titulo-select-producto">Capacidad</p>
                          <select
                            className="select-producto"
                            value={filtroCapacidadBateria}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              if (selectedValue === "") {
                                // Si el usuario selecciona "volver", maneja esa acción aquí
                                // Por ejemplo, podrías establecer la capacidad de la batería en un valor predeterminado o nulo
                                handleCapacidadBateriaChange(null);
                              } else {
                                handleCapacidadBateriaChange(selectedValue);
                              }
                            }}
                            disabled={!habilitarFiltroCapacidad}
                          >
                            <option value="">Seleccionar</option>
                            {capacidadBateriaTipos.map((capacidad) => (
                              <option key={capacidad} value={capacidad}>
                                {capacidad}
                              </option>
                            ))}
                            <option value="">volver</option>
                          </select>
                        </label>
                      </div>
                    </li>

                    <li>
                      <div>
                        <label>
                          <p className="titulo-select-producto">Altura</p>
                          <select
                            className="select-producto"
                            value={alturaSeleccionada}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              if (selectedValue === "") {
                                handleAlturaChange(null); // Si se selecciona "Seleccionar", establece la altura seleccionada como nula
                              } else {
                                handleAlturaChange(selectedValue);
                              }
                            }}
                            disabled={!habilitarAltura}
                          >
                            <option value="" disabled={!alturaSeleccionada}>
                              Seleccionar
                            </option>
                            {alturaRelacionadas.map((altura) => (
                              <option key={altura} value={altura}>
                                {altura || "No aplica"}
                              </option>
                            ))}
                            <option value="">volver</option>
                          </select>
                        </label>
                      </div>
                    </li>

                    <li>
                      <label>
                        <p className="titulo-select-producto">
                          Capacidad Energía
                        </p>

                        <select
                          className="select-producto"
                          value={capacidadBateriaSeleccionada}
                          onChange={(e) => {
                            handleCapacidadChange(e.target.value);
                          }}
                          disabled={!habilitarCapacidadBateria}
                        >
                          <option value="">Seleccionar</option>
                          {capacidadFiltradas.map((tipo) => (
                            <option key={tipo} value={tipo}>
                              {tipo || "No Aplica"}
                            </option>
                          ))}
                        </select>
                      </label>
                    </li>

                    <li>
                      <button
                        className="limpiar-filtros"
                        onClick={limpiarFiltros}
                      >
                        Limpiar filtros
                      </button>
                    </li>
                  </ul>
                )}
              </Grid>

              <Grid item {...productosGridProps}>
                <Grid container spacing={2}>
                  {productosFiltrados
                    .slice(startIndex, endIndex)
                    .map((producto, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={12}
                        md={numElementosPorFila}
                      >
                        <a
                          href={`/Producto/${producto.id}`}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <div className="card-producto">
                            <div className="container-imagen-producto">
                              {producto.imagen && (
                                <img
                                  src={`${backendURL}/${producto.imagen}`}
                                  alt={producto.seo || producto.nombre}
                                  className="maquinaselecion"
                                />
                              )}
                            </div>
                            <div className="informacion-productos">
                              <h1 className="titulo-producto">
                                {producto.nombre} {producto.bateria}
                              </h1>
                              <hr className="separador-producto" />
                              <h1 className="titulodescripcion">
                                Toneladas {producto.capacidad} y Altura{" "}
                                {producto.altura}{" "}
                              </h1>
                              <div className="fondoarriendo">
                                {" "}
                                <span className="informacion-producto">
                                  Arriendo o Venta
                                </span>
                              </div>
                              <button className="cotizar-producto">
                                COTIZAR{" "}
                              </button>
                            </div>
                          </div>
                        </a>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </section>
          <div className="pagination-buttons">
            <button
              onClick={() => {
                handlePageClick(currentPage - 1);
                const windowHeight = window.innerHeight;
                window.scrollTo(0, windowHeight / 2); // Hacer scroll hacia la mitad de la página al hacer clic en la página anterior
              }}
              className="prev-next"
              disabled={currentPage === 1}
            >
              <FaAngleLeft />
            </button>

            {Array.from({ length: buttonsToShow }, (_, i) => {
              const page = currentPage - 1 + i;
              // Si el número de página es mayor que el último conjunto de páginas disponibles, no mostrar el botón
              if (page + 1 > totalPagesFiltered || page + 1 > currentPage + 3) {
                return null;
              }

              return (
                <button
                  key={page + 1}
                  onClick={() => {
                    handlePageClick(page + 1);
                    const windowHeight = window.innerHeight;
                    window.scrollTo(0, windowHeight / 2); // Hacer scroll hacia la mitad de la página al hacer clic en una página específica
                  }}
                  className={currentPage === page + 1 ? "active" : ""}
                >
                  {page + 1}
                </button>
              );
            })}

            <button
              onClick={() => {
                handlePageClick(currentPage + 1);
                const windowHeight = window.innerHeight;
                window.scrollTo(0, windowHeight / 2); // Hacer scroll hacia la mitad de la página al hacer clic en la página siguiente
              }}
              className="prev-next"
              disabled={currentPage === totalPagesFiltered}
            >
              <FaAngleRight />
            </button>
          </div>
        </>
      )}
    </>
  );
}
