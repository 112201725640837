
import ReactPlayer from 'react-player';
import { Grid } from "@mui/material";
import "../../style/estructura racks/estrucrack.css"

import React from "react";


export default function VentajaRacks ( { rack} ) {
    


    return(
        <>
        <section>
            <Grid container className="ventajas-rack-estructura">
                
                <Grid xs={2} sm={2} className="separador-racks-principal"> 
                <div className="separador-linea-rack"></div>
                </Grid>
                <Grid xs={10} sm={10}> 
                <div className="contenedor-descripcion-rack">
                <h1 className="titulo-ventaja-estructura">descripción</h1>   
                
                <p className="descripcion-rack-final-estrcutura">
                {rack.descripcion_2}
                </p>
                </div>
                
                </Grid>
            </Grid>
        </section>
        <section className="video-racks-estructura">
            <div className="contenedor-video-racks">
            <ReactPlayer url={rack.video} className="videoproducto" width="80%" height="100%" style={{ maxHeight: '70vh' }}   loop controls/>
            </div>
        </section>
        </>
    )
}