
import Portadahome from "../components/home/slider-portada";
import Contacthome from "../components/home/contacto-home";
import ServiciosHome from "../components/home/servicios-home";
import PorductosHome from "../components/home/productos";
import ConoceMaquinarias from "../components/home/maquinarias";
import ClientesHome from "../components/home/clientes-home";
import "../style/home/home.css";

export default function Home() {
  return (
    < >
    <section> <Portadahome/> </section>
   <section> <Contacthome/> </section>
   <section> <ServiciosHome/> </section>
   <section > <PorductosHome/> </section>
   <section ><ConoceMaquinarias/></section>
    <section ><ClientesHome/></section> 

    </>
  )
}
