import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../style/home/home.css";
import axios from "axios";

import imageninfo from "../../img/home/contactohome/GRUA.png";

export default function Contacthome() {
  const [modalOpen, setModalOpen] = useState(false);
  const [videos, setVideos] = useState([]);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 2000,
    });
  }, []);

  const handleOpenModal = (url) => {
    setModalOpen(true);
    setSelectedVideoUrl(url);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedVideoUrl("");
  };

  useEffect(() => {
    axios
      .get(`${backendURL}/api/get/all/videos`)
      .then((response) => {
        const portadaVideos = response.data.filter(
          (video) => video.tipo === "portada"
        );
        setVideos(portadaVideos);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, []);

  return (
    <>
      <section data-aos="fade-down">
        <Grid container className="contenedor-home">
          <Grid item xs={12} sm={6} className="subcontenedor-home-info-primero">
            {videos.map((video) => (
              <div key={video.id} className="video-principal-home">
                {video.imagen && (
                  <img
                    src={`${backendURL}/${video.imagen}`}
                    alt={video.alt_seo || video.titulo}
                    className="imagen-video-segunda-info-home"
                  />
                )}
                <button
                  className="button-video-home"
                  onClick={() => handleOpenModal(video.url)}
                >
                  <svg
                    viewBox="0 0 448 512"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    width="26px"
                  >
                    <path
                      d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} sm={6} className="subcontenedor-home-info-primero">
            <a href="/Contacto">
              <div className="contiene-cotizar-home">
                <img
                  src={imageninfo}
                  alt="stacker"
                  title="stacker"
                  className="imagen-video-segunda-info-home"
                  layout="responsive"
                />
                <div className="info-segundo-home">
                  <h3 className="subtitulodos-segundo-home">
                    Conoce el valor y disponibilidad de todas nuestras máquinas
                    de forma rápida y eficiente. Sólo haz clic aquí.
                  </h3>

                  <button className="button type1">
                    <span className="btn-txt">COTIZAR</span>
                  </button>
                </div>
              </div>
            </a>
          </Grid>
        </Grid>
      </section>{" "}
      {modalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div>
              <iframe
                className="video-cliente"
                src={selectedVideoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
