
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import '../../style/nosotros/nosotros.css';
import '../../style/estructura racks/estrucrack.css';
import LoaderModal from '../otroscomponentes/louding';

export default function Portadaestrucrack({rack}) {

  const [windowWidth, setWindowWidth] = useState(0);
  const backendURL = process.env.REACT_APP_BACKEND_URL; 


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Ahora, solo hay un useEffect con windowWidth como dependencia

  if (!rack) {
    return <><LoaderModal/></>; // o manejar el caso de que no haya datos
  }


  return (
    <section className="portada-home">
      <div className="contenedor-img-nosotros">
        {rack.imagen_portada && rack.imagen_responsive && (
          <img
            src={
              windowWidth < 840
                ? `${backendURL}/${rack.imagen_responsive}`
                : `${backendURL}/${rack.imagen_portada}`
            }
            className="portada-nosotros"
            alt="stacker"
            title="stacker"
            width={100}
            height={100}
            layout="responsive"
          />
        )}
        <div className="informacion-estrcutura-racks-portada">
          <h1 className="titulo-estrcutura-racks-portada">{rack.nombre}</h1>
          <hr />
          <h3 className="descripcion-principal-estrcutura-rack-portada">{rack.descripcion_1}</h3>
        </div>
      </div>
    </section>
  );
}