import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../style/nosotros/nosotros.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Portadaracks() {
  const [portadasData, setPortadasData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Agregar el event listener para detectar cambios en el tamaño de la ventana
    window.addEventListener("resize", handleResize);

    // Obtener el ancho de la ventana al cargar el componente
    setWindowWidth(window.innerWidth);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/api/get/all/racksportadas`
        );
        setPortadasData(response.data);
      } catch (error) {
        console.error("Error al obtener los datos de portadas:", error);
        // Manejar el error, por ejemplo, mostrar un mensaje al usuario
      }
    };

    fetchData();
  }, []);

  return (
    <section className="portada-home">
      {portadasData.map((portadaItem) => (
        <div key={portadaItem.id} className="contenedor-img-nosotros">
          {portadaItem.imagen && portadaItem.imagen_responsive && (
            <img
              src={
                windowWidth < 1000
                  ? `${backendURL}/${portadaItem.imagen_responsive}`
                  : `${backendURL}/${portadaItem.imagen}`
              }
              className="portada-nosotros"
              alt={portadaItem.alt_seo}
              title={portadaItem.alt_seo}
            />
          )}
          <div className="info-portada-nosotros" data-aos="zoom-in-right">
            <h1 className="titulo-portada-nosotros">{portadaItem.titulo} </h1>
          </div>
        </div>
      ))}
    </section>
  );
}
