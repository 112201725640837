import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Portadaestrucrack from "../components/estructura racks/portada";
import Cotizarrack from "../components/estructura racks/cotizacion";
import VentajaRacks from "../components/estructura racks/ventaja-estructura";



export default function RackPage () {
    const { id } = useParams();
    const [rackData, setrackData] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
  
    useEffect(() => {
      obtenerDatospordcuto();
    }, []);
  
    const obtenerDatospordcuto = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/api/get/racksproductos/${id}`
        );
        const curso = response.data;
        setrackData(curso);
      } catch (error) {
        console.error(error);
      }
    };
  
    if (!rackData) {
      // Aquí podrías mostrar un spinner o un mensaje de carga mientras se obtienen los datos
      return <div>Cargando...</div>;
    }
    return(
        <> 
        <section >
            <Portadaestrucrack rack={rackData}/>
        </section>
        <section >
            <Cotizarrack rack={rackData}/>
        </section>
        <section >
            <VentajaRacks rack={rackData}/>
        </section>
        </>
    )
}