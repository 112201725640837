import { createContext, useContext, useState, useEffect } from "react";
const CarritoContexto = createContext();

export const CarritoContextoProvider = ({ children }) => {
  const [productosEnCotizar, setProductosEnCotizar] = useState([]);

  const agregarcotizar = (producto) => {
    setProductosEnCotizar((prevProductos) => {
      const nuevosProductos = [...prevProductos, { ...producto }];

      localStorage.setItem("/Contacto", JSON.stringify(nuevosProductos));
      return nuevosProductos;
    });
  };

  const eliminarDelCarrito = (productoId) => {
    setProductosEnCotizar((prevProductos) => {
      const nuevosProductos = prevProductos.filter((p) => p.id !== productoId);

      localStorage.removeItem("/Contacto", JSON.stringify(nuevosProductos));
      return nuevosProductos;
    });
  };

  useEffect(() => {
    const productosGuardados = localStorage.getItem("/Contacto");
    if (productosGuardados) {
      const productos = JSON.parse(productosGuardados);
      setProductosEnCotizar(productos);

      // Event listener para el evento beforeunload
      const beforeUnloadHandler = () => {
        // Eliminar el elemento del almacenamiento local
        localStorage.removeItem("/Contacto");

        // Ejecutar la lógica de recarga solo después de la segunda actualización manual
        window.location.reload();
      };

      window.addEventListener("beforeunload", beforeUnloadHandler);

      // Limpiar el listener cuando el componente se desmonte
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, []);
  return (
    <CarritoContexto.Provider
      value={{
        productosEnCotizar,
        agregarcotizar,
        eliminarDelCarrito,
      }}
    >
      {children}
    </CarritoContexto.Provider>
  );
};

export const useCarritoContexto = () => {
  return useContext(CarritoContexto);
};
