import React from "react";
import Portadaracks from "../components/racks/portada";
import Racks from "../components/racks/racks-prodcuto";
export default function Rack() {
    return(
        <>
        <Portadaracks/>
        <Racks/>
        </>
    )
}