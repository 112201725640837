import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../style/home/home.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination, Navigation } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Grid } from "@mui/material";

/* imagenes */
import lineas from "../../img/home/contactohome/lineas.png";

export default function PorductosHome() {
  const navigate = useNavigate();
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  useEffect(() => {
    AOS.init({
      // Configura las opciones aquí
      duration: 500000,
    });
  }, []);
  // ...

  // Evento de clic en el botón "VER MÁS"
  const handleVerMasClick = (id, categoriaId) => {
    setSelectedProductId(id);
    setCategoriaSeleccionada(categoriaId);
    navigate(`/Productos?id=${id}&categoria=${categoriaId}`);
  };

  const [categorias, setCategorias] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/api/get/all/categorias`
        );

        if (response.status === 200) {
          const data = response.data;

          setCategorias(data); // Actualiza el estado con los datos obtenidos
        } else {
          console.error("Error al obtener datos");
        }
      } catch (error) {
        console.error("Error de red", error);
      }
    };

    fetchData(); // Llamada a la función al montar el componente
  }, []);
  return (
    <Grid container className="container-producto-categoria">
      <section className="titulo-nosotros-home" data-aos="fade-down">
        <img src={lineas} alt="stacker" title="stacker" />
        <h1 className="titulo-home-nosotros">PRODUCTO STACKER</h1>
      </section>
      <Swiper
        slidesPerView={4}
        spaceBetween={0}
        breakpoints={{
          240: {
            slidesPerView: 1,
            spaceBetween: 4,
          },
          500: {
            slidesPerView: 1,
            spaceBetween: 1,
          },
          600: {
            slidesPerView: 1,
            spaceBetween: 1,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 1,
          },
          880: { slidesPerView: 2, spaceBetween: 1 },
          1200: {
            slidesPerView: 3,
            spaceBetween: 1,
          },
          1565: {
            slidesPerView: 3,
            spaceBetween: 1,
          },
          1566: {
            slidesPerView: 4,
            spaceBetween: 1,
          },
        }}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="slider-home-producto"
      >
        {categorias.map((categoria) => (
          <SwiperSlide key={categoria.id} className="categoria-slider-home">
            <div className="categoria-home" data-aos="fade-down">
              <div className="card-contenido-categoria">
                <div className="imagen-categoria-producto-home">
                  {/* Utiliza la propiedad 'imagen' directamente desde los datos */}
                  {categoria.imagen && (
                    <img
                      src={`${backendURL}/${categoria.imagen}`}
                      alt={categoria.alt_seo || categoria.nombre} // Puedes ajustar según tus necesidades
                      width={100} // Ajusta el valor de acuerdo a tus necesidades
                      height={100} // Ajusta el valor de acuerdo a tus necesidades
                    />
                  )}
                </div>
                <div className="info-categoria-home">
                  <span className="titulo-contenido-categoria">
                    {categoria.descripcion}
                  </span>
                  <p className="descripcion-producto-categoria-home">
                    {categoria.nombre}
                  </p>
                  <p className="decripcion-contenido-categoria">
                    <button
                      className="boton-cotizacion-home-producto"
                      onClick={() => handleVerMasClick(categoria.id)}
                    >
                      VER MÁS
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Grid>
  );
}
